.info-real-estate-page__heading-bottom {
  display: grid;
  grid-template-columns: minmax(0, 8fr) minmax(0, 4fr);
  // align-items: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: minmax(0, 1fr);
  }

  .heart-icon {
    flex-shrink: 0;
    font-size: 2rem;
    cursor: pointer;
    color: var(--colorLightBlue);

    &.active {
      fill: var(--colorLightBlue);
      path {
        fill: var(--colorLightBlue);
      }
    }
  }

  .info-real-estate-page__left {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;

    @media screen and (max-width: 768px) {
      margin-bottom: 1rem;
    }

    h1 {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }
    }

    .address {
      display: flex;
      align-items: center;

      svg {
        color: var(--colorOrange);
        font-size: 1.5rem;
        flex-shrink: 0;
      }

      p {
        margin-left: 0.5rem;
        font-size: 1rem;
        font-weight: 500;
        text-transform: capitalize;
        line-height: 1.2;
      }
    }
  }

  .info-real-estate-page__right {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .add-favorite {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }

    .price {
      text-align: right;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        width: 100%;
        align-items: flex-start;
      }

      & > * + * {
        margin-top: 0.5rem;
      }

      .price__starting-price {
        font-size: 0.9rem;

        span {
          font-size: 1.2rem;
          font-weight: 500;

          span {
            text-decoration: line-through;
            text-decoration-thickness: 1.5px;
          }
        }
      }

      .price__minimum-bid-price {
        font-size: 1.1rem;

        span {
          color: var(--colorOrange);
          font-size: 2rem;
          font-weight: 500;
        }
      }

      .price__minimum-bid-increment {
        font-size: 0.9rem;
        line-height: 2rem;

        span {
          font-size: 1.2rem;
          font-weight: 500;
        }
      }
    }
  }
}
