.blog-single__intro {
  .section {
    display: flex;
    padding: 0;
    position: relative;
    top: 2rem;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      top: unset;
    }

    .illustration {
      width: calc((100% / 12) * 7);
      border-radius: 1rem;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .description {
      padding-left: 3rem;
      width: calc((100% / 12) * 5);

      @media screen and (max-width: 768px) {
        width: 100%;
        padding-left: 0;
      }

      .heading-blog-singola {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;

        @media screen and (max-width: 768px) {
          margin-top: 2rem;
          margin-bottom: 0;
        }
      }

      h1 {
        margin-bottom: 0;
        @media screen and (max-width: 576px) {
          padding-bottom: 2rem;
        }
      }
    }
  }
}
