.subscription-container {
  width: 49%;
  @media screen and (max-width: 992px) {
    width: 95%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  h4 {
    padding-bottom: 2rem;
  }
}
.empty-orange {
  color: var(--colorOrange);
  border: 2px solid var(--colorOrange) !important;
}
.data-box {
  padding: 2rem;
  background: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  align-self: flex-start;

  //   min-height: 20vh;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   padding-bottom: 4px;
  .active-subscription {
    color: var(--colorLightBlue);
    font-size: 1.5rem;
    font-weight: 500;
  }
  .inactive-subscription {
    color: var(--colorOrange);
    font-size: 1.5rem;
    font-weight: 500;
  }
}
.subscription-date {
  //   font-size: 1.2rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: rgb(145, 145, 145);
  padding-top: 8px;
  padding-bottom: 2rem;
}
.unsubscribe-container {
  position: absolute;
  bottom: 12px;
  right: 2rem;
  font-size: 12px;
}
.unsubscribe-link {
  color: var(--colorLightBlue);
  cursor: pointer;
  font-weight: 500;
}
.transactions-table {
  overflow: auto;
  scrollbar-width: auto !important;
  scrollbar-color: var(--colorOrange) #eee !important;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--colorOrange) !important;
  }
  &::-webkit-scrollbar-track {
    background: #eee !important;
  }
}
.transaction-body {
  max-height: 35vh;
}
.transaction-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid var(--colorLightestBlack);

  .transaction-title {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  .transaction-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    @media screen and (max-width: 576px) {
      font-size: 0.8rem;
    }
  }
}
