.payment-feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--colorLightBlack);

  & > * + * {
    margin-top: 1rem;
  }

  img {
    width: 200px;

    @media screen and (max-width: 576px) {
      width: 250px;
    }
  }

  svg {
    font-size: 10rem;

    &.failed {
      color: rgb(191, 5, 5);
    }
  }

  h1.failure {
    color: rgb(191, 5, 5);
  }

  p {
    font-size: 1.5rem;
  }

  .btn {
    margin-top: 3rem;
  }
  .app-description__download {
    // display: grid;
    // grid-template-columns: repeat(2, minmax(0, 1fr));
    // column-gap: 2rem;
    // row-gap: 1rem;
    padding-top: 1rem;
    display: flex;

    @media screen and (max-width: 768px) {
      // padding: 0 2rem;
      grid-template-columns: minmax(0, 1fr);
    }
    .apple-store {
      width: 120px;
      height: 40px;
      margin-right: 1rem;
      background-image: url("/assets/app-store.svg");
    }
    .play-store {
      width: 135px;
      height: 40px;

      background-image: url("/assets/google-play.svg");
      background-size: cover;
    }

    // a {
    //   background: var(--colorLightBlue);
    //   padding: 0.6rem;
    //   display: flex;
    //   align-items: center;
    //   border-radius: 0.6rem;
    //   color: #fff;
    //   font-weight: 500;

    //   &:hover {
    //     background: #fff;
    //     color: var(--colorLightBlue);

    //     svg {
    //       color: var(--colorLightBlue);
    //     }
    //   }

    //   & > * + * {
    //     margin-left: 1rem;
    //   }

    //   svg {
    //     color: #fff;
    //     font-size: 2rem;
    //   }

    //   p {
    //     margin-bottom: 0;
    //   }
    // }
  }
}
