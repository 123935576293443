.blog-single__carousel {
  width: 100%;
  .swiper-container {
    position: relative;

    .swiper-slide {
      height: 100%;
      // width: 50%;
    }
  }

  img {
    display: block;
    border-radius: 1rem;
    display: block;
    position: relative;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 500px;

    @media screen and (max-width: 768px) {
      height: 200px;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;

    &.arrow-left {
      left: 20px;
    }

    &.arrow-right {
      right: 20px;
    }
  }
}
