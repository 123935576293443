.select-component {
  background: var(--colorBackground);
  border-radius: 999px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  border: 0;
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    box-shadow: 0 0 2px var(--colorLightBlack);
  }

  &.focused {
    box-shadow: 0 0 2px var(--colorLightBlack);
  }

  .placeholder {
    padding: 1rem 1rem;
    width: 100%;
    position: relative;

    @media screen and (max-width: 768px) {
      padding: 1rem 0;
      padding-left: 1rem;
    }

    &.active {
      z-index: 5;
      svg {
        transform: rotate(180deg) translateY(50%);
      }
    }

    svg {
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
    }
  }

  .options-container {
    z-index: 4;
    position: absolute;
    top: 0;
    padding-top: 3rem;
    left: 0;
    overflow: hidden;
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
    background: var(--colorBackground);
    border-radius: 16px;
    box-shadow: 0 0 5px;

    .option {
      padding: 0.5rem 1rem;
      cursor: pointer;

      &:hover {
        background: var(--colorLightestBlack);
      }
    }
  }
}
