.faq-page {
  .hero {
    text-align: center;
    @media screen and (max-width: 576px) {
      .all-faq-btn {
        display: none;
      }
    }

    .filter-faq {
      display: flex;
      justify-content: space-between;
      // flex-wrap: wrap;
      // max-width: 600px;
      margin: 0 auto;

      @media screen and (max-width: 576px) {
        flex-direction: column;
        align-items: center;
        display: none;
      }

      & > * {
        margin-top: 1rem;
      }
    }
  }

  .bg-section {
    background-image: url("/assets/scene/triangoliFAQ.svg");
    background-position: center;
    background-repeat: repeat;
  }

  .faqs {
    .heading-faqs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      img {
        width: 200px;
      }
    }

    .faq-list {
      display: flex;

      @media screen and (max-width: 576px) {
        flex-direction: column;
      }

      .list-vertical {
        width: 50%;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 576px) {
          width: 100%;
        }

        &:last-child {
          margin-left: 2rem;

          @media screen and (max-width: 576px) {
            margin-left: 0;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .link-related {
    display: flex;
    justify-content: center;

    .link {
      width: 70%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      padding: 2rem;
      border-radius: 1rem;

      @media screen and (max-width: 576px) {
        width: 100%;
        flex-direction: column;
      }

      h4 {
        font-size: 1.5rem;
        font-weight: 400;

        @media screen and (max-width: 576px) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
