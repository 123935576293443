.login-page {
  position: relative;
  background-image: url("/assets/scene/11large.svg");
  height: 80vh;
  background-repeat: no-repeat;
  background-position: center -50px;
  background-size: cover;

  @media screen and (max-width: 576px) {
    background-size: unset;
    background-position: bottom center;
  }

  .intro {
    text-align: center;

    @media screen and (max-width: 576px) {
      padding-bottom: 1.5rem;
    }

    h3 {
      font-weight: 400;

      .link {
        color: var(--colorOrange);
        text-decoration: underline;
        font-weight: 500;
      }
    }
  }
  .forgotten-password {
    text-decoration: underline;
    color: var(--colorOrange);
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 0 !important;
    padding-left: 1rem;
    cursor: pointer;
  }

  .form-component {
    width: calc((100% / 12) * 4);
    margin: 0 auto;
    padding: 3rem;
    background: #fff;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      width: calc((100% / 12) * 6);
    }

    @media screen and (max-width: 576px) {
      width: 100%;
      padding: 1.5rem;
    }

    & > * + * {
      margin-top: 1rem;
    }

    .error {
      text-transform: uppercase;
      color: red;
      font-size: 0.8rem;
      font-weight: 500;
      text-align: center;
    }
  }
}
