.account-data-page {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    grid-template-columns: minmax(0, 1fr);
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;

    .alert-success {
      color: rgb(2, 124, 2);
      padding-top: 1.5rem;
      font-weight: 500;
    }
  }

  .user-data {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 2rem;
  }

  .button-container {
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--colorLightestBlack);
    padding-top: 2rem;
  }
}
