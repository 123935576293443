@import "./reset.css";

@import "./font/font.css";

@import "./variables";
@import "./layout";
@import "./typography";
@import "./buttons";
@import "./utilities";

@import "placeholder-loading/src/scss/placeholder-loading";

@import "swiper/swiper.min.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: var(--colorBackground);
  color: var(--colorBlack);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "AE", sans-serif;
  // user-select: none;

  &.no-scroll {
    position: relative;
    // height: 100vh;
    overflow: hidden;
    touch-action: none;
    -ms-touch-action: none;
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  & > div:nth-child(3) {
    flex-grow: 1;
    // padding-top: 8rem;
  }
}

select,
input {
  width: 100%;
  flex-grow: 1;
  appearance: none;
  border: 0;
  font-family: var(--fontFamily);
  font-size: 1rem;
  font-weight: 500;
  background: var(--colorBackground);
  border-radius: 999px;
  color: var(--colorBlack);
}

ul {
  list-style: none;
}

option {
  appearance: none;
}

button {
  border: 0;
  font-family: var(--fontFamily);
}

input,
textarea {
  font-size: 1rem;
  font-weight: 500;
  appearance: none;
  outline: none;
  border: 0;
  background: var(--colorBackground);
  border-radius: 2rem;
  // padding: 1rem 1.5rem;
  font-family: "AE", sans-serif;

  &:focus {
    outline: none;
  }
}

textarea {
  resize: none;
}

input[type="checkbox"] {
  width: 5px;
  height: 5px;
  // background-color: red;
  padding: 0.8rem;
  border-width: 3px;
  border-color: var(--colorBackground);
  border-style: solid;
  border-radius: 50%;

  &:checked {
    background: var(--colorOrange) !important;
  }
}

label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.expired-auction {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  filter: blur(8px);
}
