.home-page__hero {
  min-height: 70vh;
  background-image: url("/assets/scene/2.svg");
  background-size: 90%;
  background-position: 390px 10px;
  background-repeat: no-repeat;

  @media screen and (max-width: 1140px) {
    min-height: unset;
  }
  @media screen and (max-width: 768px) {
    background-image: none;
  }

  .overlay-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(#000, 0.5);
    z-index: 10;
    transition: 1s;
    opacity: 0;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    .window-video {
      position: relative;
      @media screen and (max-width: 1140px) {
        width: 80%;
      }
      @media screen and (max-width: 768px) {
        width: 90%;
      }
      svg {
        position: absolute;
        top: -3rem;
        right: -3rem;
        z-index: 5;
        background: #fff;
        font-size: 3rem;
        padding: 1rem;
        border-radius: 50%;

        @media screen and (max-width: 768px) {
          top: -4rem;
          right: 1rem;
        }

        &:hover {
          cursor: pointer;
          background: var(--colorBackground);
        }
      }

      video {
        max-width: 1000px;
        width: 100%;
        border-radius: 2rem;

        @media screen and (max-width: 768px) {
          border-radius: 0;
        }
      }
    }
  }

  .intro {
    margin-bottom: 5rem;
    width: calc((100% / 12) * 6);

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 2rem;
    }

    h1 {
      margin-bottom: 2.5rem;
      font-size: 3.8rem;
      line-height: 1;
    }

    .intro-bottom {
      position: relative;

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      p {
        width: 100%;
        font-size: 1.3rem;
      }

      .video {
        position: absolute;
        right: -7rem;
        bottom: -1rem;

        @media screen and (max-width: 768px) {
          position: static;
          top: unset;
          bottom: unset;
        }

        svg {
          flex-shrink: 0;
          background: var(--colorLightBlue);
          font-size: 6rem;
          padding: 1.5rem;
          color: #fff;
          font-weight: 300;
          border-radius: 50%;
          cursor: pointer;
          // box-shadow: 0 0 20px var(--colorLightBlue);

          &:hover {
            filter: brightness(80%);
          }

          @media screen and (max-width: 768px) {
            font-size: 4rem;
            padding: 1rem;
          }
        }

        .video-button {
          @media screen and (max-width: 576px) {
            display: none;
          }
        }

        .video-mobile {
          display: none;
          width: 100%;

          @media screen and (max-width: 576px) {
            display: block;
            padding-top: 2rem;
          }
        }
      }
    }
  }
}
