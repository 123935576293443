.blog-single__content {
  @media screen and (max-width: 576px) {
    padding-top: 2rem;
  }
  .bottom {
    padding-top: 5rem;
    display: grid;
    grid-template-columns: minmax(0, calc(100% / 12 * 8)) minmax(
        0,
        calc(100% / 12 * 4)
      );
    row-gap: 3rem;

    @media screen and (max-width: 768px) {
      grid-template-columns: minmax(0, 1fr);
      padding-top: unset;
    }

    .text {
      font-size: 1.2rem;

      p {
        margin-bottom: 1.5rem;
      }
    }

    .share {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @media screen and (max-width: 768px) {
        flex-direction: row;
        align-items: flex-start;
      }

      span {
        position: absolute;
        left: 50%;
        bottom: 0;
        display: block;
        transform: rotate(-90deg) translate(-80%, -190%);

        @media screen and (max-width: 768px) {
          order: -1;
          margin: 0;
          position: relative;
          transform: none;
          left: 0;
        }
      }
    }
  }
}
