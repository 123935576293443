.heading-immobile-page {
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--colorLightestBlack);
  margin: 2rem 0;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }

  & > * {
    width: calc(100% / 3);

    @media screen and (max-width: 768px) {
      width: unset;
    }
  }

  .back {
    cursor: pointer;
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      width: 50%;
    }

    span {
      margin-left: 0.5rem;
    }
  }

  .auction-date {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      width: 100%;
      order: 1;
      margin-top: 1rem;
    }

    .dot {
      margin-left: 0.5rem;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      animation: pulse2 1s infinite;
    }
  }

  .navigation {
    display: flex;
    justify-content: flex-end;

    & > a:last-child {
      margin-left: 1rem;
    }

    @media screen and (max-width: 768px) {
      justify-content: space-around;

      span {
        display: none;
      }
    }

    .left,
    .right {
      display: flex;
      align-items: center;

      &:hover {
        svg {
          background: var(--colorOrange);
          color: #fff;
        }
      }

      & > *:last-child {
        margin-left: 0.5rem;

        @media screen and (max-width: 768px) {
          margin-left: 0;
        }
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
