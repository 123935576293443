.comparison__hero {
  display: flex;
  align-items: center;
  min-height: 60vh;
  flex-direction: column;
  // width: 50%;
  text-align: center;

  .illustration {
    min-height: 35vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-top: 24px;
    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }
  .illustration-left,
  .illustration-right {
    width: 50%;
    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }
  .illustration-left {
    @media screen and (max-width: 992px) {
      margin-bottom: 16px;
    }
  }
  .illustration-right {
    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .big {
    width: 80%;

    @media screen and (max-width: 992px) {
      width: 100%;
      margin-left: 0;
    }
  }
  .description {
    margin-left: 2rem;
    width: 80%;

    @media screen and (max-width: 992px) {
      width: 100%;
      margin-left: 0;
    }

    p {
      font-size: 1.2rem;
    }
  }
}
