.single-faq-page {
  padding: 1rem 0;
  border-bottom: 2px solid var(--colorLightestBlack);

  .question {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 1.2rem;
      font-weight: 400;
    }

    svg {
      flex-shrink: 0;
      margin-left: 1rem;
      color: var(--colorOrange);
      font-size: 1.5rem;
    }
  }

  .answer {
    opacity: 0;
    height: 0;
    opacity: 1;
    height: min-content;
    padding-top: 8px;

    // &.active {
    //   opacity: 1;
    //   height: min-content;
    //   padding-top: 8px;
    // }
  }
}
