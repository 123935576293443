.services-page__services-section {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }

  .description {
    width: 45%;

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }

  .illustration {
    width: 45%;
    background: url("/assets/scene/10a.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: -170px center;

    @media screen and (max-width: 992px) {
      height: 300px;
      width: 75%;
    }

    @media screen and (max-width: 576px) {
      width: 100%;
    }

    @media screen and (max-width: 992px) {
      background-position: center center;
    }
  }
}
