.form-component {
  margin: 0 auto;
  width: 100%;
  padding: 2rem;
  background: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 1rem;
  }

  & > * + * {
    margin-top: 1rem;
  }

  &.small {
    width: calc((100% / 12) * 4);

    @media screen and (max-width: 992px) {
      width: calc((100% / 12) * 8);
    }

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }

  &.medium {
    width: calc((100% / 12) * 6);

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }

  &.large {
    width: calc((100% / 12) * 10);

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }

  input,
  textarea {
    font-size: 1rem;
    font-weight: 500;
    appearance: none;
    outline: none;
    border: 0;
    background: var(--colorBackground);
    border-radius: 2rem;
    padding: 1rem 1.5rem;
    font-family: "AE", sans-serif;
  }

  input {
    max-height: 3rem;
  }

  input[type="checkbox"],
  input[type="radio"] {
    width: 5px;
    height: 5px;
    flex-grow: 0;
    padding: 0.5rem;
    border-width: 3px;
    border-color: var(--colorBackground);
    border-style: solid;
    border-radius: 50%;

    &:checked {
      background: var(--colorOrange);
    }

    &.default {
      background: var(--colorOrange);
      pointer-events: none;
    }
  }
}

.form-component__checkbox {
  display: flex;
  align-items: center;

  input {
    margin-top: 0;
    margin-right: 1rem;
    background: #fff;
  }

  label {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 0.8rem;
  }
}

.form-container__radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;

  &:first-of-type {
    border-bottom: #ddd solid 1px;
  }

  .radio__radio {
    display: flex;

    input {
      margin-top: 0;
      margin-right: 1rem;
    }

    label {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 0.8rem;
      flex-grow: 1;
    }
  }

  .radio__icons {
    display: flex;

    svg {
      width: 5rem;
    }
  }
}
