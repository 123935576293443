.info-real-estate__specs {
  .spec {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    .right-spec {
      font-weight: 500;
    }
  }
}
