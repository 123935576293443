.why-asta-destinations__contacts {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
  }

  & > * {
    width: 45%;

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }

  .description {
    @media screen and (max-width: 992px) {
      order: -1;
    }

    p {
      margin-bottom: 1.5rem;
    }

    ul {
      padding-left: 2rem;
      list-style: disc;

      li {
        padding-bottom: 0.8rem;
        line-height: 1.5;
      }
    }
  }
}
