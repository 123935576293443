#wrapper-search-bar {
  background: #fff;
  padding: 0 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 0.5rem rgba(#000, 0.1);

  @media screen and (max-width: 768px) {
    padding: 0 1rem;
    &:not(.homepage) {
      margin: 0 1rem;
      margin-bottom: 1rem;
    }
  }

  &.sticky {
    border-radius: unset;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;

    .simple {
      height: 5rem;
      padding: 0;
      .logo {
        display: block;
      }

      .simple-filters {
        width: 80%;

        // .filters {
        //   margin-left: 1rem;
        // }
      }

      .view-options {
        display: flex;
      }
    }

    .advanced {
      .logo {
        display: block;
        flex-shrink: 0;
      }

      .advanced-filters-section {
        .advanced-filters {
          // margin-left: 1rem;
          width: 85%;
        }

        .button {
          display: flex;
          align-items: flex-end;
        }
      }

      .view-options {
        display: flex;
        align-items: flex-end;
        flex-shrink: 0;
      }
    }
  }

  .search-button {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 768px) {
      width: unset;
    }
    &.advanced-button {
      display: none;
      margin-bottom: 2rem;

      @media screen and (max-width: 768px) {
        display: flex;
      }
    }

    &.active {
      .reset {
        visibility: visible;
        margin-right: 0.5rem;
      }
    }

    @media screen and (max-width: 768px) {
      margin-top: 1rem;

      &.hide {
        display: none;
      }
    }

    .reset {
      font-size: 1.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      visibility: hidden;

      &:hover {
        color: red;
      }
    }
  }

  .simple {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;

    .logo {
      display: none;
      width: 10%;

      img {
        width: 80%;
      }
    }

    .simple-filters {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: start;
      }

      .filters {
        width: 75%;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 0.5rem;
        row-gap: 0.5rem;

        @media screen and (max-width: 768px) {
          width: 100%;
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media screen and (max-width: 576px) {
          grid-template-columns: minmax(0, 1fr);
        }
      }
    }

    .view-options {
      width: 10%;
      display: none;
      justify-content: flex-end;
      align-items: center;

      & > * + * {
        margin-left: 0.5rem;
      }

      svg {
        flex-shrink: 0;
        cursor: pointer;
        color: var(--colorLightBlue);
        font-size: 1.5rem;

        &.active {
          background: var(--colorLightBlue);
          color: #fff;
          font-size: 2.2rem;
          padding: 0.5rem;
          border-radius: 50%;
        }

        @media screen and (max-width: 992px) {
          font-size: 1rem;
        }
      }
    }
  }

  .advanced {
    display: flex;
    flex-direction: column;

    .logo {
      display: none;
      width: 10%;
    }

    .advanced-filters {
      display: flex;
    }

    .search-button {
      align-self: center;
    }

    .advanced-filters-section {
      display: flex;
      width: 85%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      .advanced-filters {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        column-gap: 0.5rem;
        row-gap: 0.5rem;
        margin-bottom: 1rem;

        @media screen and (max-width: 768px) {
          width: 100%;
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media screen and (max-width: 576px) {
          grid-template-columns: minmax(0, 1fr);
        }
      }

      .button {
        display: none;
        width: 15%;
        // margin-left: 1.5rem;

        .advanced-search {
          margin-bottom: 1rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          svg {
            margin-left: 1rem;
            font-size: 2rem;
            border: 1px solid var(--colorLightestBlack);
            border-radius: 1rem;
            padding: 0.5rem;
          }
        }
      }
    }

    .view-options {
      display: none;
      width: 10%;
    }
  }
}
