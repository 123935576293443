.about-us-page__story {
  display: flex;
  align-items: center;

  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
  }

  .description,
  .numbers {
    width: 50%;

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }

  .description {
    padding-right: 3rem;

    @media screen and (max-width: 992px) {
      padding-right: 0;
      margin-bottom: 2rem;
    }
  }

  .numbers {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;

    @media screen and (max-width: 576px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 1rem;
    }

    .number {
      text-align: center;
      background: #fff;
      border-radius: 1rem;
      padding: 2rem 2.5rem;

      h4 {
        color: var(--colorLightBlue);
        font-size: 3.7rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }
    }
  }
}
