.card-real-estate-extended__carousel {
  width: 30%;
  margin: 0;

  .auction-date {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 40;
    background: #fff;
    padding: 0.7rem;
    border-radius: 12px;
    display: flex;
    align-items: center;
    span {
      font-size: 0.8rem;
      font-weight: 500;
    }

    .dot {
      margin-left: 0.5rem;
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 50%;
      animation: pulse2 1s infinite;
    }

    @keyframes pulse2 {
      from {
        box-shadow: 0 0 0 currentColor;
      }
      to {
        box-shadow: 0 0 5px currentColor;
      }
    }
  }

  img {
    height: 250px;
  }

  .map-box {
    height: 100%;
  }

  .apartment-img {
    display: block;
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  .apartment-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      width: 70%;
      height: 100%;
    }
  }

  .prev-mini-img,
  .next-mini-img {
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem;

    &.swiper-button-disabled {
      filter: opacity(20%);
      pointer-events: none;
    }

    polyline {
      color: #fff;
      box-shadow: 0 0 5px #000;
    }
  }

  .prev-mini-img {
    left: 5%;
  }

  .next-mini-img {
    right: 5%;
  }
}
