.card-real-estate-component {
  border-radius: 1rem;
  position: relative;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin: 1rem 0;
  overflow: hidden;
  height: 95%;
  display: flex;
  flex-direction: column;

  .description,
  .bottom {
    padding: 0 1rem;
  }

  .description {
    flex-grow: 1;
    // max-height: 12rem;
    background: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;

    & > * + * {
      margin-top: 1rem;
    }

    a {
      flex-basis: 30%;
    }

    h4 {
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: capitalize;

      &:hover {
        color: var(--colorOrange);
      }
    }

    p,
    span {
      font-size: 0.8rem;
    }

    .address {
      // height: 2rem;
      line-height: 1rem;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    .numbers {
      // margin: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .price {
        font-size: 1.4rem;
        color: var(--colorOrange);
      }

      .m2 {
        font-size: 1rem;
        font-weight: 500;
      }

      .line {
        width: 1px;
        height: 1.3rem;
        background: #ccc;
      }
    }
  }

  .bottom {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background: #fae6db;
    display: flex;
    justify-content: space-between;

    span {
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      font-weight: 500;

      span {
        margin-left: 0.5rem;
      }
    }

    .auction-date {
      display: flex;
      align-items: center;

      .dot {
        margin-left: 0.5rem;
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        animation: pulse2 1.2s infinite;
      }
    }
  }
}

@keyframes pulse2 {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0;
  }
}
