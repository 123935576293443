@font-face {
  font-family: "AE";
  src: url("AELight.woff2") format("woff2"), url("AELight.woff") format("woff"),
    url("AELight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "AE";
  src: url("AERegular.woff2") format("woff2"),
    url("AERegular.woff") format("woff"),
    url("AERegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "AE";
  src: url("AEMedium.woff2") format("woff2"),
    url("AEMedium.woff") format("woff"), url("AEMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "AE";
  src: url("AEBold.woff2") format("woff2"), url("AEBold.woff") format("woff"),
    url("AEBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
