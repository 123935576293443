h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

h1 {
  margin: 0;

  &.general {
    font-size: 3rem;
    font-weight: 500;
  }

  &.big {
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 5rem;
    line-height: 5rem;

    @media screen and (max-width: 992px) {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }
}

h2.general {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 2rem;
  line-height: 1.2;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
}

a {
  color: var(--colorBlack);
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &.white {
    color: #fff;
  }
}

span {
  &.date {
    color: var(--colorBlack);
    font-size: 1rem;
  }

  &.category {
    display: block;
    color: var(--colorOrange);
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  &.uppercase {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8rem;
  }
}

.semi-bold {
  font-weight: 500;
}

.bold {
  font-weight: bold;
}

.light {
  font-weight: 300;
}

p.general {
  font-size: 1.3rem;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.5;
  color: var(--colorLightBlack);

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
}

p {
  line-height: 1.6rem;
}
