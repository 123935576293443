.price-plans-component__advice {
  background-color: #fff;
  background-image: url("/assets/scene/11large.svg");
  background-size: cover;
  background-position: center 320px;
  background-repeat: no-repeat;
  width: 55%;
  padding: 2rem 3rem 6rem;
  border-radius: 1rem;
  display: flex;
  position: relative;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    margin: 0 1rem;
    width: 100%;
    padding: 2rem;
  }

  & > * + * {
    margin-bottom: 2rem;
  }

  .close-advice {
    position: absolute;
    top: -3rem;
    right: -3rem;
    z-index: 5;
    background: #fff;
    font-size: 3rem;
    padding: 1rem;
    border-radius: 50%;

    @media screen and (max-width: 768px) {
      top: -4rem;
      right: 1rem;
    }

    &:hover {
      cursor: pointer;
      background: var(--colorBackground);
    }
  }

  .heading-banner {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 500;

    @media screen and (max-width: 576px) {
      font-size: 1.2rem;
    }

    span {
      display: inline;
      font-size: 2.5rem;

      @media screen and (max-width: 576px) {
        font-size: 1.5rem;
      }
    }
  }

  .price-section {
    text-align: center;
    color: var(--colorLightBlue);
    font-size: 2rem;

    @media screen and (max-width: 576px) {
      font-size: 1.2rem;
    }

    .price {
      font-size: 4rem;
      font-weight: 700;

      @media screen and (max-width: 576px) {
        font-size: 2rem;
      }

      sup {
        font-size: 2rem;
      }
    }

    .time {
      font-size: 1rem;
    }
  }

  .features {
    // display: grid;
    // grid-template-columns: repeat(2, minmax(0, 1fr));
    // gap: 1rem;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .feature {
      display: flex;

      svg {
        color: var(--colorLightBlue);
        font-size: 1.5rem;
        flex-shrink: 0;
      }

      span {
        margin-left: 0.5rem;
        font-size: 0.8rem;
        font-weight: 500;
      }
    }
  }

  .acquista {
    align-self: center;
  }

  .free-plan {
    align-self: center;
    font-size: 0.8rem;

    &:hover {
      text-decoration: underline;
      color: var(--colorOrange);
    }
  }
}
