.navbar {
  display: flex;
  position: relative;
  margin: 0;

  .container-fluid {
    height: 8rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1096px) {
      padding: 1rem;
      // margin-top: 1rem;
      // height: 4rem;
    }

    img {
      width: 10rem;
    }

    svg {
      font-size: 1.2rem;
      cursor: pointer;

      &.hamburger {
        font-size: 1.8rem;
      }
    }

    .navbar-items {
      display: flex;
      align-items: center;

      & > * + * {
        margin-left: 2rem;
      }

      a {
        display: flex;
        align-items: center;

        &:hover {
          color: var(--colorLightBlue);
        }

        &.btn:hover {
          color: #fff;
        }

        &.active {
          color: var(--colorLightBlue);
        }

        &.cart-link {
          position: relative;

          svg {
            font-size: 1.2rem;
          }
        }

        span {
          margin-left: 0.5rem;
        }
      }

      .cart-dot {
        position: absolute;
        top: -2px;
        right: -4px;
        display: flex;
        justify-content: center;
        align-self: center;
        background-color: var(--colorOrange);
        width: 12px;
        height: 12px;
        color: #fff;
        font-size: 0.7rem;
        border-radius: 50%;
      }
    }
  }
}
