.why-asta__benefits {
  display: flex;

  .img {
    width: calc((100% / 12) * 9);
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .description {
    width: calc((100% / 12) * 5);

    @media screen and (max-width: 992px) {
      width: 100%;
    }

    .benefits-list {
      margin-bottom: 3rem;

      .benefit {
        padding: 2rem 0;
        border-top: 1px solid #ddd;

        &:last-child {
          border-bottom: 1px solid #ddd;
        }

        &.active {
          border-top: 2px solid var(--colorOrange);

          .benefit__heading {
            h5 {
              color: var(--colorOrange);
            }

            svg {
              transform: rotate(180deg);
              color: var(--colorOrange);
            }
          }

          p {
            display: block;
            margin-top: 1rem;
          }
        }

        .benefit__heading {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;

          h5 {
            font-size: 1.5rem;
            font-weight: 500;
          }

          svg {
            font-size: 1.5rem;
          }
        }

        p {
          display: none;
        }
      }
    }
  }
}
