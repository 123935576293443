.popup-container {
  display: flex;
  background-color: #00000050;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh !important;
  width: 100vw !important;
  align-items: center;
  justify-content: center;
  z-index: 9001;
}
.popup-card {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 30%;
  //   height: 30%;
  justify-content: center;
  background-color: #fff;
  border-radius: 16px;
  padding: 32px;
  z-index: 10;
  @media (max-width: 1200px) {
    padding: 24px;
    width: 50%;
  }
  @media (max-width: 768px) {
    padding: 24px;
    width: 90%;
  }
  input {
    padding: 1rem;
  }
}
