#wrapper-status-bar {
  background: var(--colorBackground);
  border-bottom: 1px solid var(--colorLightestBlack);
  margin-bottom: 2rem;
  height: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.sticky {
    margin: 0;
    max-width: inherit;
    padding: 0 2rem;
    position: fixed;
    height: 4rem;
    top: 5rem;
    z-index: 9;
    width: 100%;

    .top {
      display: none;
      margin-bottom: 0;
    }

    .bottom {
      .view-options {
        display: none;
      }

      .advanced-search {
        display: flex;
      }
    }
  }

  @media screen and (max-width: 768px) {
    height: unset;
    padding: 24px 1rem;
  }

  .top {
    display: flex;
    justify-content: flex-end;

    .advanced-search {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      .advanced-active-filters {
        margin-right: 1rem;
        color: var(--colorLightBlue);
      }

      svg {
        margin-left: 1rem;
        font-size: 2rem;
        border: 1px solid var(--colorLightestBlack);
        border-radius: 1rem;
        padding: 0.5rem;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      // justify-content: unset;

      .number-results {
        width: 100%;
      }

      .view-options-mobile {
        margin-left: 2rem;
        padding-bottom: 1rem;
      }
    }

    .legenda {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // justify-content: space-between;

      @media screen and (max-width: 768px) {
        margin-bottom: 32px;
        min-width: 100%;
      }

      &-mobile {
        justify-content: center;
        width: 100%;
      }

      & > div {
        display: flex;
        align-items: center;
        position: relative;

        &:not(:first-of-type) {
          margin-top: 12px;
        }

        @media screen and (max-width: 768px) {
          width: 100%;
          &:first-of-type {
            margin-right: 0px;
          }
        }
      }

      .orangeDot {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: var(--colorOrange);
        margin-right: 4px;
      }
      .azureDot {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 4px;
        background-color: var(--colorLightBlue);
      }
      .tooltip {
        position: absolute;
        width: 300px;
        top: 0;
        right: 0;
        background-color: #fff;
        padding: 8px 12px;
        border-radius: 12px;
        transform: translate(100%, -25%);
        z-index: 1000;
        @media screen and (max-width: 576px) {
          transform: translate(30%, -100%);
          width: 270px;
          top: -2px;
          font-size: 0.9rem;
        }
      }
    }
    .number-results {
      &-mobile {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
    svg.active {
      color: var(--colorLightBlue);

      path {
        fill: var(--colorLightBlue);
      }
    }

    .number-results,
    .sort-option {
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        margin-bottom: 1rem;

        span {
          display: none;
        }
      }

      svg {
        cursor: pointer;
        font-size: 1.5rem;
        margin-left: 1rem;
      }
    }

    .sort-option {
      display: flex;
      justify-content: center;
      align-items: center;
      // flex: 3;

      .select-component {
        width: 200px;
        margin-left: 1rem;

        @media screen and (max-width: 768px) {
          margin-left: 0;
        }
      }
    }

    .view-options {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media screen and (max-width: 768px) {
        justify-content: center;
      }

      & > * + * {
        margin-left: 1rem;
      }

      svg {
        cursor: pointer;
        color: var(--colorLightBlue);
        font-size: 1.5rem;

        &.active {
          background: var(--colorLightBlue);
          color: #fff;
          font-size: 2.2rem;
          padding: 0.5rem;
          border-radius: 50%;
        }
      }
    }

    .view-options-mobile {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media screen and (max-width: 768px) {
        justify-content: center;
      }

      & > * + * {
        margin-left: 1rem;
      }

      svg {
        cursor: pointer;
        color: var(--colorLightBlue);
        font-size: 1.5rem;

        &.active {
          background: var(--colorLightBlue);
          color: #fff;
          font-size: 2.2rem;
          padding: 0.5rem;
          border-radius: 50%;
        }
      }
    }

    .advanced-search {
      display: none;
      cursor: pointer;
      align-items: center;
      justify-content: flex-end;

      .advanced-active-filters {
        margin-right: 2rem;
        color: var(--colorLightBlue);
      }

      svg {
        margin-left: 1rem;
        font-size: 2rem;
        border: 1px solid var(--colorLightestBlack);
        border-radius: 1rem;
        padding: 0.5rem;
      }
    }
  }
}
