.input-select-component {
  background: var(--colorBackground);
  border-radius: 999px;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    box-shadow: 0 0 2px var(--colorLightBlack);
  }

  .placeholder {
    position: relative;
    width: 100%;

    &.active {
      z-index: 5;
      svg {
        transform: rotate(180deg) translateY(50%);
      }
    }
  }

  input {
    padding: 1rem 1rem;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90%;

    &::placeholder {
      color: var(--colorBlack);
      opacity: 1;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }

  .options-container {
    overflow-y: auto;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    max-height: 15rem;
    width: 100%;
    padding-top: 58px;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 1rem;
    box-shadow: 0 0 5px;
    background: var(--colorBackground);

    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
    }

    .option {
      padding: 1rem 1rem;
      cursor: pointer;

      &:hover {
        background: var(--colorLightestBlack);
      }
    }
  }
}
