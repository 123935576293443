.real-estate-not-found-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5vh 0;
  text-align: center;

  p {
    font-size: 2rem;
  }

  a {
    margin-top: 2rem;
    color: var(--colorLightBlue);

    &:hover {
      text-decoration: underline;
    }
  }
}
