.services-page__legal-list {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
  }

  .description {
    width: 45%;

    @media screen and (max-width: 992px) {
      width: 100%;
    }

    h5 {
      margin-bottom: 1.5rem;
      font-size: 2rem;
      font-weight: 500;
    }

    ul {
      padding-left: 2rem;
      list-style: disc;

      li {
        line-height: 2;
      }
    }
  }

  .illustration {
    width: 45%;
    background: url("/assets/scene/7.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 992px) {
      width: 100%;
      margin-top: 1.5rem;
    }
  }
}
