.navigation-arrow {
  margin: 2rem 0;
  display: flex;
  justify-content: center;

  .arrow-right {
    margin-left: 1.5rem;
  }
}

.arrow-left {
  flex-shrink: 0;
  cursor: pointer;
  background: #fff;
  color: var(--colorBlack);
  font-size: 3.5rem;
  padding: 1.2rem;
  border-radius: 50%;

  &.small {
    font-size: 2rem;
    padding: 0.5rem;
  }

  &:hover {
    color: #fff;
    background: var(--colorOrange);
  }

  &.swiper-button-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.arrow-right {
  flex-shrink: 0;
  cursor: pointer;
  background: #fff;
  color: var(--colorBlack);
  font-size: 3.5rem;
  padding: 1.2rem;
  border-radius: 50%;

  &.small {
    font-size: 2rem;
    padding: 0.5rem;
  }

  &:hover {
    color: #fff;
    background: var(--colorOrange);
  }

  &.swiper-button-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.orange {
    background: var(--colorOrange);
    color: #fff;

    &:hover {
      filter: opacity(80%);
    }
  }
}
