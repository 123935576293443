.research-real-estate__results-box {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  align-items: start;
  column-gap: 2rem;
  row-gap: 2rem;
  padding-bottom: 1.5rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 6rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media screen and (max-width: 576px) {
    display: block;
    gap: 0;
    grid-template-columns: minmax(0, 1fr);
    padding-left: 1rem;
    padding-right: 1rem;

    & > * {
      margin-bottom: 2rem;
    }
  }

  .subscribe-for-more {
    height: 100%;
    padding: 3rem 0;
    grid-column: span 2 / span 2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }
}
