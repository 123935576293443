.info-real-estate-page__booking {
  margin-bottom: 1rem;
  width: 100%;

  &.done {
    pointer-events: none;
    background: #fff;
    color: var(--colorOrange);
    border: 1px solid var(--colorOrange);

    svg {
      display: none;
    }
  }

  svg {
    color: #fff;
    font-size: 1.5rem;
  }
}

.overlay__booking {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  background: #fff;
  padding: 2rem;
  border-radius: 1rem;

  @media screen and (max-width: 768px) {
    width: 95%;
    padding: 1rem;
  }

  .close-banner {
    position: absolute;
    top: -3rem;
    right: -3rem;
    z-index: 5;
    background: #fff;
    font-size: 3rem;
    padding: 1rem;
    border-radius: 50%;

    @media screen and (max-width: 768px) {
      top: -4rem;
      right: 1rem;
    }

    &:hover {
      cursor: pointer;
      background: var(--colorBackground);
    }
  }

  .month-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    & > * + * {
      margin-left: 1rem;

      @media screen and (max-width: 768px) {
        margin-left: 0.5rem;
      }
    }

    .month {
      color: var(--colorOrange);
      font-weight: 500;
      font-size: 1.8rem;
      height: 100%;
      text-transform: capitalize;

      @media screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }

    .months-change {
      display: flex;
      align-items: center;
      position: relative;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
  }

  .heading-calendario-aste {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;

    .day {
      text-align: center;
      background: var(--colorLightBlue);
      padding: 1rem 0.5rem;
      color: #fff;
      text-transform: capitalize;
      font-size: 1.2rem;
    }
  }

  .blocks {
    margin-bottom: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    .block {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ddd;
      font-size: 1.2rem;
      padding: 0.5rem;
      cursor: pointer;
      height: 3rem;

      @media screen and (max-width: 768px) {
        font-size: 0.9rem;
        padding: 0.2rem;
      }

      &.disabled {
        color: #ddd;
        pointer-events: none;
      }

      &.active {
        background: var(--colorOrange);
        color: #fff;
      }
    }
  }

  .select-component {
    margin-bottom: 2rem;
    width: 10rem;

    .options-container {
      padding-top: 0.5rem;
      padding-bottom: 3rem;
      transform: translateY(calc(-100% + 3rem));
    }
  }

  .buttons-booking {
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: 1rem;
    }

    svg {
      font-size: 3rem;
      cursor: pointer;
      padding: 0.8rem;
      border-radius: 50%;

      &:hover {
        background: var(--colorBackground);
      }
    }
  }

  .feedback {
    font-size: 2rem;
  }

  .close {
    color: var(--colorOrange);
    margin-top: 2rem;
    cursor: pointer;
  }
}
