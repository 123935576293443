.home-page__why {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 576px) {
    flex-direction: column;
  }

  .app-description {
    display: flex;
    flex-direction: column;
    width: calc((100% / 12) * 5);

    @media screen and (max-width: 576px) {
      width: 100%;
    }

    h2 {
      margin-bottom: 3rem;
      font-size: 3rem;
    }

    p {
      margin-bottom: 2rem;
      line-height: 1.3;
      font-size: 1.2rem;
    }
  }

  .app-illustration {
    width: 50%;

    @media screen and (max-width: 576px) {
      order: -1;
      width: 100%;
    }

    img {
      max-width: 80%;
      margin: 0 auto;
      display: block;
      position: relative;
      right: 0.5rem;

      @media screen and (max-width: 768px) {
        margin-bottom: 2rem;
      }
    }
  }
}
