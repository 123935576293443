.home-page__plans {
  background-image: url("/assets/scene/triangoli.svg");
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;

  h2 {
    text-align: center;
  }
}
