.empty-list {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  min-height: 10vh;
  color: var(--colorLightestBlack);
}

.wishlist {
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 1.2rem;
  }

  .wishlist-item {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background: #fff;
    border-radius: 1rem;

    .left {
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        // align-items: unset;
      }

      .apartment-placeholder {
        border-radius: 0.5rem;

        img {
          height: 80px;
          object-fit: fill;
        }
      }

      img {
        width: 150px;
        height: 90px;
        object-fit: cover;
        border-radius: 0.5rem;

        @media screen and (max-width: 576px) {
          width: 80px;
          height: 80px;
        }
      }

      .info-item {
        margin-left: 2rem;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 768px) {
          margin-left: 1rem;
        }

        .top {
          h3 {
            font-size: 1.3rem;
            font-weight: 500;

            @media screen and (max-width: 576px) {
              font-size: 1rem;
            }
          }
        }

        .bottom {
          margin-top: 0.5rem;
          display: flex;
          align-items: center;

          @media screen and (max-width: 576px) {
            flex-direction: column;
            justify-content: center;
            align-items: start;
          }

          .date-container {
            display: flex;
            align-items: center;

            @media screen and (max-width: 768px) {
              margin-bottom: 0.5rem;
            }
          }

          svg {
            color: var(--colorLightBlue);
            font-size: 1.3rem;
          }

          span {
            margin-left: 0.5rem;
            font-size: 0.9rem;

            &.general {
              margin-left: 2rem;

              @media screen and (max-width: 576px) {
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      @media screen and (max-width: 576px) {
        display: none;
      }

      .allegati-list {
        display: flex;

        & > * + * {
          margin-left: 1.2rem;
        }

        .allegato {
          padding: 0 1rem;
          height: 3rem;
          display: flex;
          align-items: center;
          background: #fff;
          border: 1px solid #000;
          border-radius: 999px;
          cursor: pointer;

          &:hover {
            filter: opacity(50%);
          }

          svg {
            color: var(--colorLightBlue);
            font-size: 1.2rem;
          }

          span {
            margin-left: 0.5rem;
            text-transform: capitalize;
          }
        }
      }

      a {
        margin-left: 2rem;
      }
    }
  }
}
