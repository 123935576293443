.services-page__publicity {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
  }

  & > * {
    width: 45%;

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }
}
