.why-asta__hero {
  text-align: center;
  height: 100vh;
  background-image: url("/assets/scene/12.svg");
  background-repeat: no-repeat;
  background-position: center 32vh;
  padding: 4rem 0 0;

  @media screen and (max-width: 992px) {
    background-position: center 35vh;
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    background-size: calc(100% - 2rem);
    background-position: center bottom;
  }

  p {
    max-width: 1140px;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
}
