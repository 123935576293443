.research-real-estate-page {
  .intro {
    h1 {
      margin-bottom: 2rem;
    }
  }

  #wrapper-results {
    margin-bottom: 2rem;
    &.sticky {
      padding-top: 14rem;
    }
  }

  .no-results {
    color: var(--colorLightestBlack);
    display: flex;
    justify-content: center;
    font-size: 2rem;
    padding: 8rem 0;
  }

  .list {
    @media screen and (max-width: 576px) {
      display: none;
    }
  }
}
