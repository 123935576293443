.blog-list-component {
  .article-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;
    margin-bottom: 5rem;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (max-width: 576px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .article {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;

      & > * + * {
        margin-top: 1rem;
      }

      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        display: block;
        border-radius: 1rem;

        &:hover {
          filter: brightness(80%);
        }
      }
    }

    .heading-article {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .category {
        margin-bottom: 0;
      }
    }

    h4 {
      font-size: 1.5rem;
      font-weight: 500;

      &:hover {
        color: var(--colorOrange);
      }
    }
  }
}
