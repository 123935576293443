.pagination-section {
  margin-top: 2rem;

  .pagination {
    display: flex;
    font-weight: bold;
    align-items: center;
  }

  .paginationItem {
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;

    a {
      opacity: 0.4;

      &.active {
        color: black;
        opacity: 1;
      }
    }
  }

  .previous {
    margin-left: auto;
    font-size: 20px;
    cursor: pointer;
    order: 1;
  }

  .next {
    padding-left: 16px;
    font-size: 20px;
    cursor: pointer;
    order: 1;
  }

  .break {
    display: none;
  }
}
