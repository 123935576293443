.about-us-page__hero {
  height: 100vh;
  background-image: url("/assets/scene/9.svg");
  background-size: cover;
  background-position: center 260px;
  background-repeat: no-repeat;
  text-align: center;

  @media screen and (max-width: 1367px) {
    background-position: center 200px;
  }

  @media screen and (max-width: 768px) {
    background-size: 100%;
    background-position: center bottom;
    height: 80vh;
  }

  p {
    margin-bottom: 3rem;
    line-height: 1.5;
    max-width: 900px;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
}
