:root {
  --colorBackground: #efeeec;
  --colorBlack: #222;
  --colorLightBlack: #444;
  --colorLightestBlack: #c4c4c4;
  --colorLightWhite: #ffefff;
  --colorOrange: #ff580a;
  --colorLightBlue: #00d1ce;
  --colorPink: #f1dfd5;

  --fontFamily: "AE", sans-serif;
}
