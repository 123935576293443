.why-asta-destinations__hero {
  background-image: url("/assets/scene/triangoliBR.svg");
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  min-height: 60vh;
  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
  .img {
    background-image: url("/assets/scene/2.svg");
    background-position: center center;
    width: 50%;
    min-height: 60vh;
    background-size: cover;
    @media screen and (max-width: 992px) {
      width: 100%;
      min-height: 35vh;
    }
  }
  .description {
    width: 50%;
    margin-left: 2rem;
    @media screen and (max-width: 992px) {
      width: 100%;
      margin-left: 0;
    }
    p {
      font-size: 1.2rem;
    }
  }
}
