.info-real-estate__intro-specs {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: #fff;
  border-radius: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  h3 {
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .voices {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .voice {
      border-top: 1px solid var(--colorLightestBlack);
      padding-top: 1rem;
      width: 100%;

      @media screen and (max-width: 768px) {
        padding-bottom: 1rem;
        padding-top: 0;
        border-top: none;
        border-bottom: 1px solid var(--colorLightestBlack);
      }

      h6 {
        margin-bottom: 1rem;
        font-weight: 400;
        font-size: 1rem;
      }

      .date {
        display: flex;
        align-items: center;
        color: var(--colorLightBlue);

        span {
          margin-left: 0.5rem;
        }
      }

      div {
        font-weight: 500;
      }
    }
  }
}
