.info-real-estate__info-bar {
  background: #fff;
  border-radius: 1rem;
  display: flex;
  padding: 1rem 3rem;
  justify-content: space-around;
  margin-bottom: 2rem;
  align-items: flex-end;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

  .unita {
    display: flex;
    align-items: center;

    svg {
      font-size: 1.3rem;
      color: var(--colorOrange);
    }

    span {
      margin-left: 0.5rem;
    }
  }
}
