.info-real-estate-page__attachments {
  margin-top: 3rem;

  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }

  .attachments-list {
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: 0.5rem;
    }

    .attachment {
      cursor: pointer;
      background: #fff;
      padding: 1rem 2rem;
      border-radius: 999px;
      display: flex;
      align-items: center;

      &:hover {
        filter: opacity(50%);
      }

      svg {
        color: var(--colorLightBlue);
        font-size: 1.5rem;
        flex-shrink: 0;
      }

      span {
        margin-left: 1rem;
        text-transform: capitalize;
        font-weight: 500;
        overflow: hidden;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    }
  }
}
