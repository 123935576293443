.home-page__carousel {
  overflow-x: hidden;
  padding-left: calc((100vw - 1200px) / 2);

  @media screen and (max-width: 992px) {
    padding-left: unset;
  }

  .list {
    margin-bottom: 4rem;
    width: calc(100vw - calc((100vw - 1200px) / 2));
    display: flex;

    @media screen and (max-width: 576px) {
      width: 100%;
      flex-direction: column;
    }

    .title {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      h3 {
        font-size: 2.5em;
        font-weight: 500;

        @media screen and (max-width: 576px) {
          margin: 1.5rem 0;
          display: block;
        }
      }

      img {
        width: 250px;

        @media screen and (max-width: 576px) {
          margin: 1.5rem 0;
          width: 100%;
          display: none;
        }
      }
    }

    .carousel-list {
      width: 80%;

      @media screen and (max-width: 992px) {
        width: 100%;
      }

      .home-slider {
        height: unset;
      }

      .get-premium-card {
        margin: 1rem 0;
        height: unset;
        background: #fff;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        a {
          margin-bottom: 1rem;
        }
      }

      .swiper-container {
        .swiper-wrapper {
          .swiper-slide {
            width: 25%;

            @media screen and (max-width: 992px) {
              width: 30%;
            }

            @media screen and (max-width: 768px) {
              width: 40%;
            }

            @media screen and (max-width: 576px) {
              width: 70%;
            }
          }
        }
      }
    }
  }
}

.arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .navigation-arrow {
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }

  .btn {
    position: absolute;
    height: 100%;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
