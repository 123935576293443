.about-us-page__values {
  background-image: url("/assets/scene/triangoli.svg");
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;

  .title {
    text-align: center;
  }

  .values-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;

    @media screen and (max-width: 768px) {
      grid-template-columns: minmax(0, 1fr);
    }

    .value {
      background: #fff;
      border-radius: 1rem;
      padding: 2rem;

      h4 {
        margin-top: 1rem;
        font-size: 1.5rem;
        font-weight: 500;
      }

      p {
        font-size: 1rem;
        margin-top: 1rem;
      }
    }
  }
}
