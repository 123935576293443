.account-payment-page {
  .button-container {
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--colorLightestBlack);
    padding-top: 2rem;
  }

  .container-dati {
    display: flex;

    @media screen and (max-width: 576px) {
      flex-direction: column;
    }

    .alert-success {
      color: rgb(2, 124, 2);
      padding-top: 1.5rem;
      font-weight: 500;
    }
  }
}
