.gdpr-pages {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--colorLightBlack);
  padding-bottom: 32px;
  @media screen and (max-width: 576px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  h1 {
    font-size: 48px;
    margin-bottom: 2rem;
  }
  h2 {
    font-size: 24px;
    margin: 24px 0 8px;
  }
  a {
    text-decoration: underline;
    color: var(--colorOrange);
  }
}
