.info-real-estate__slider-immobile {
  background: #fff;
  position: relative;
  background: var(--colorBackground);

  @media screen and (max-width: 768px) {
    height: 300px;
  }

  .starting-price__discount {
    font-size: 1.2rem;
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;
    z-index: 2;
    color: #fff;
    background: var(--colorLightBlue);
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
      width: 3rem;
      height: 3rem;
      top: -0.5rem;
      right: -0.5rem;
    }
  }

  & > .swiper-container {
    border-radius: 1rem;
    height: 100%;
    position: relative;

    .swiper-slide {
      .apartment-placeholder {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        display: block;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .controls {
    cursor: pointer;
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 2;
    display: flex;

    & > * + * {
      margin-left: 1rem;
    }

    .wrapper-icon {
      background: var(--colorLightBlue);
      border-radius: 50%;
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: #fff;
        font-size: 1.5rem;

        &:hover {
          scale: 1.1;
        }
      }
    }
  }

  .swiper-immobile-prev,
  .swiper-immobile-next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    color: #ccc;
    font-size: 2.5rem;

    &.swiper-button-disabled {
      filter: opacity(20%);
      pointer-events: none;
    }
  }

  .swiper-immobile-next {
    right: 3%;
  }

  .swiper-immobile-prev {
    left: 3%;
  }
}
