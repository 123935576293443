.why-asta__purpose {
  background-image: url("/assets/scene/triangoli.svg");
  background-size: 85%;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;

  @media screen and (max-width: 576px) {
    background-size: 120%;
    background-repeat: repeat;
  }

  .destinations {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 400px));
    justify-content: center;
    gap: 2rem;

    @media screen and (max-width: 992px) {
      grid-template-columns: minmax(0, 1fr);
    }

    .destination {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: 0.4s;
      background: #fff;
      border-radius: 1rem;
      padding: 2rem;

      @media screen and (max-width: 992px) {
        width: 100%;
      }

      &:hover {
        transform: translateY(-15px);
        box-shadow: 0 15px 10px var(--colorLightestBlack);

        @media screen and (max-width: 576px) {
          box-shadow: 0 0 0;
          transform: none;
        }
      }

      .img {
        height: 200px;
        background-size: cover;
        background-position: center;
      }

      h5 {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1.1rem;
        margin-bottom: 2rem;
      }
    }
  }
}
