.info-real-estate {
  position: relative;
}
.expired-auction-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 8rem);
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .expirated-auction-advert {
    font-size: 2rem;
    font-weight: 500;
    color: #fff;
    margin-bottom: 2rem;
    text-align: center;
    @media screen and (max-width: 576px) {
      padding: 0 32px;
      touch-action: none;
      -ms-touch-action: none;
    }
  }
}

.info-real-estate-page.blur {
  filter: blur(8px);
}
.info-real-estate-page {
  .ph-item {
    height: 150vh;
  }

  .info-real-estate-page__no-result {
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-size: 2rem;
    }

    a {
      margin-top: 2rem;
      color: var(--colorLightBlue);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 20;
    background: rgba(#000, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .intro {
    display: grid;
    column-gap: 2rem;
    row-gap: 1rem;
    grid-template-columns: minmax(0, 8fr) minmax(0, 4fr);

    @media screen and (max-width: 768px) {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  .intro-free {
    display: grid;
    column-gap: 2rem;
    row-gap: 1rem;
    grid-template-columns: minmax(0, 1fr);

    @media screen and (max-width: 768px) {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  .info-real-estate-page__content {
    display: grid;
    gap: 2rem;
    grid-template-columns: minmax(0, 8fr) minmax(0, 4fr);

    @media screen and (max-width: 768px) {
      grid-template-columns: minmax(0, 1fr);
    }

    h4 {
      font-size: 1.5rem;
      font-weight: 500;

      &.mb {
        margin-bottom: 2rem;
      }
    }

    .info {
      .text {
        margin-bottom: 4rem;

        & > div {
          border-bottom: 1px solid var(--colorLightestBlack);
          padding: 1.5rem 0;
          .heading-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            svg {
              &.active {
                color: var(--colorOrange);
                transform: rotate(180deg);
              }
            }
          }

          p,
          .specs-list {
            margin-top: 2rem;
            line-height: 1.2;
          }

          p {
            line-height: 1.5rem;
          }
        }

        .info-real-estate-page__map-section {
          .map-scheda {
            margin-top: 2rem;
          }
        }
      }
    }
  }
}
