.research-results-list {
  display: flex;
  flex-direction: column;

  .subscribe-for-more {
    height: 100%;
    padding: 3rem 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }
}
