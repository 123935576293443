.join-auction-button {
  margin-bottom: 1rem;
  width: 100%;

  &.done {
    pointer-events: none;
    background: #fff;
    color: var(--colorOrange);
    border: 1px solid var(--colorOrange);

    svg {
      display: none;
    }
  }

  svg {
    color: #fff;
    font-size: 1.5rem;
  }
}

.overlay__join-auction {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 40;
  align-items: center;
  background: #fff;
  padding: 2rem;
  border-radius: 1rem;

  @media screen and (max-width: 768px) {
    width: 90%;
  }

  .question {
    font-size: 1.5rem;
  }

  .controls-alert {
    margin-top: 2rem;
    display: flex;

    & > * + * {
      margin-left: 2rem;
    }

    svg {
      font-size: 3rem;
      cursor: pointer;

      &.confirm:hover {
        color: green;
      }

      &.undo:hover {
        color: red;
      }
    }
  }

  .feedback {
    font-size: 2rem;
  }

  .close {
    color: var(--colorOrange);
    margin-top: 2rem;
    cursor: pointer;
  }
}
