.spinner-component {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex: 1;

  .spinner {
    border: 6px solid #fff;
    border-top: 6px solid var(--colorOrange);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;

    &.darker {
      border-color: var(--greyDarkColor);
      border-top-color: var(--colorOrange);
    }

    &.lighter {
      border-color: #fff;
      border-top-color: var(--colorOrange);
    }

    @media screen and (max-width: 768px) {
      width: 30px;
      height: 30px;
      border-width: 6px;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.spinner-component-small {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex: 1;

  .spinner {
    border: 3px solid #fff;
    border-top: 3px solid var(--colorOrange);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 2s linear infinite;

    &.darker {
      border-color: var(--greyDarkColor);
      border-top-color: var(--colorOrange);
    }

    &.lighter {
      border-color: #fff;
      border-top-color: var(--colorOrange);
    }

    @media screen and (max-width: 768px) {
      width: 24px;
      height: 24px;
      border-width: 3px;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
