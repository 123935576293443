.blog-single__social {
  position: relative;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    align-items: center;

    & > * {
      margin-top: 0;
      margin-left: 1rem;
    }
  }

  & > * {
    margin-top: 0.5rem;
  }

  svg {
    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
    }

    &:hover {
      color: var(--colorOrange);
    }
  }
}
