.carousel__carousel-full {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#000, 0.8);
  z-index: 4;

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 576px) {
      justify-content: center;
    }

    .heading-slider-immobile-overlay {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > * {
        width: calc(100% / 3);
      }

      .position {
        text-align: center;
      }

      .close {
        text-align: right;

        svg {
          cursor: pointer;
          color: #fff;
          font-size: 2rem;
        }
      }
    }
  }

  .slider-immobile__slider-full {
    position: relative;

    .swiper-container {
      img {
        width: 100%;
        height: 80vh;
        object-fit: cover;
        @media screen and (max-width: 768px) {
          height: 85vh;
        }
      }
    }

    .swiper-full-immobile-prev,
    .swiper-full-immobile-next {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;
      color: #fff;
      font-size: 2.5rem;

      &.swiper-button-disabled {
        filter: opacity(20%);
        pointer-events: none;
      }
    }

    .swiper-full-immobile-next {
      right: -70px;
    }

    .swiper-full-immobile-prev {
      left: -70px;
    }
  }
}
