.blog-page__blog-intro {
  background-image: url("/assets/scene/triangoliBlog.svg");
  background-position: center;
  background-repeat: repeat;

  .first-new {
    display: grid;
    grid-template-columns: minmax(0, 55%) minmax(0, 45%);
    column-gap: 5rem;
    row-gap: 2rem;

    @media screen and (max-width: 768px) {
      grid-template-columns: minmax(0, 1fr);
    }

    img {
      display: block;
      width: 100%;
      height: 500px;
      border-radius: 1rem;
      object-fit: cover;

      @media screen and (max-width: 768px) {
        height: 200px;
      }

      &.placeholder {
        object-fit: contain;
      }
    }

    .description {
      display: flex;
      flex-direction: column;

      .heading-first-new {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;

        @media screen and (max-width: 768px) {
          margin-bottom: 0;
        }
      }

      h2 {
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.2rem;
        line-height: 1.3;
        margin-bottom: 2rem;
        overflow: hidden;
        -webkit-line-clamp: 5;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      a {
        align-self: flex-end;
        display: flex;
        align-items: center;

        span {
          margin-right: 0.5rem;
        }

        svg {
          margin-left: 0.5rem;
        }
      }
    }
  }
}
