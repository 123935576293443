.our-team-page__hero {
  text-align: center;
  p {
    margin-bottom: 3rem;
    line-height: 1.5;
    max-width: 900px;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
}
