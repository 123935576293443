.price-plans-component {
  display: grid;
  justify-content: center;
  column-gap: 2rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 450px)) !important;
    row-gap: 2rem;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: block;
    z-index: 3;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card {
    position: relative;
    border-width: 4px;
    border-style: solid;
    border-color: #fff;
    background: #fff;
    border-radius: 1rem;
    text-align: center;
    transition: 0.4s;

    &.premium {
      position: relative;
      border-color: var(--colorOrange);

      &::after {
        position: absolute;
        top: -3.5rem;
        right: -3.5rem;
        display: block;
        content: "";
        width: 7rem;
        height: 7rem;
        background-image: url("/assets/scene/star-big.svg");

        @media screen and (max-width: 786px) {
          display: none;
        }
      }

      h3 {
        color: #fff;
        background: var(--colorOrange);
      }
    }

    &:hover {
      transform: translateY(-15px);
      box-shadow: 0 15px 10px var(--colorLightestBlack);

      @media screen and (max-width: 992px) {
        box-shadow: 0 0 0;
        transform: none;
      }
    }

    h3 {
      padding: 1.5rem 0;
      font-size: 2rem;
      font-weight: 300;
      border-bottom: 1px solid var(--colorLightWhite);

      span {
        font-weight: 500;
      }
    }

    h2 {
      font-size: 3rem;
      font-weight: 500;

      &.free {
        color: var(--colorLightBlue);
      }
    }

    .bottom {
      padding: 3rem;
      @media screen and (max-width: 576px) {
        padding: 2rem;
      }
      display: flex;
      flex-direction: column;
      .iva {
        font-size: 1.2rem !important;
        position: absolute;
      }
      h2 {
        height: 3rem;

        sup {
          font-size: 1rem;
          text-transform: uppercase;
          vertical-align: super;
        }

        span:nth-child(1),
        span:nth-child(2) {
          font-weight: 700;
        }

        span:first-of-type {
          font-size: 2rem;
        }

        span:nth-child(2) {
          font-size: 4rem;
        }

        span:last-of-type {
          font-size: 1.5rem;
        }
      }

      & > * + * {
        margin-top: 2rem;
      }

      .info-list {
        text-align: left;
        flex: 3;

        .info {
          padding: 0.5rem 0;
          display: flex;
          align-items: center;

          &.not-checked {
            filter: opacity(40%);

            svg {
              color: var(--colorBlack);
            }
          }
          svg {
            color: var(--colorLightBlue);
            font-size: 2rem;
          }

          span {
            margin-left: 1rem;
            flex: 9;
          }
        }
      }

      .cancel {
        font-size: 12px;
        text-transform: uppercase;
        margin-top: 16px;
        position: absolute;
        bottom: -1.6rem;
        left: 50%;
        transform: translateX(-50%);
        // background-color: red;
      }
    }
  }
}
