.services-page__footer {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
  }

  .illustration {
    width: 45%;
    background: url("/assets/scene/servizi-visita-casa.svg");
    background-size: contain;
    background-size: unset;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 992px) {
      width: 100%;
      height: 300px;
    }
  }

  .description {
    width: 45%;

    @media screen and (max-width: 992px) {
      width: 100%;
      order: -1;
    }

    p {
      margin-bottom: 1.5rem;
    }
  }
}
