.btn {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  // color: var(--colorOrange);
  background: #fff;
  font-weight: 600;
  height: 3rem;
  padding: 0 2rem;
  border-radius: 999px;
  font-size: 0.8rem;

  &:hover {
    background: var(--colorOrange);
    color: #fff;

    svg {
      color: #fff;
    }
  }

  &.between {
    justify-content: space-between;
  }

  & > * {
    flex-shrink: 0;
  }

  & > * + * {
    margin-left: 0.5rem;
  }

  &.orange {
    border-width: 2px;
    border-style: solid;
    border-color: var(--colorOrange);
    color: #fff;
    background: var(--colorOrange);
    text-transform: uppercase;
    // padding: 0 3rem;

    &:hover {
      filter: saturate(70%);
    }

    svg {
      color: #fff;
    }
  }

  &.empty {
    border-width: 2px;
    border-style: solid;
    border-color: var(--colorBlack);
    background: transparent;
    text-transform: uppercase;
    font-size: 0.8rem;
    // padding: 0 3rem;
    font-weight: 700;

    &:hover {
      color: #fff;
      background: var(--colorOrange);
      border-color: var(--colorOrange);
    }
  }

  &.big {
    font-weight: 700;
    font-size: 0.8rem;
    height: 4rem;
    padding: 0 2.5rem;
    text-transform: uppercase;
  }

  &.left {
    align-self: flex-start;
  }

  &.center {
    align-self: center;
  }

  &.right {
    align-self: flex-end;
  }

  &.margin {
    display: block;
    margin: 0 auto;
  }

  &.full-width {
    width: 100%;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.text-xl {
    font-size: 1.2rem;
    font-weight: 400;

    svg {
      font-size: 1.5rem;
    }
  }

  svg {
    font-size: 1.2rem;
    color: var(--colorOrange);
  }
}

.bg-white {
  background: #fff;
}

button:disabled {
  opacity: 0.7;
  pointer-events: none;
}
