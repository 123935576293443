.form-contact-component {
  .overlay-message {
    width: 100vw;
    height: 100vh;
    background: rgba($color: #000, $alpha: 0.4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      padding: 3rem;
      background: #fff;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      text-align: center;

      .feedback {
        display: flex;
        align-items: center;

        svg {
          color: var(--colorLightBlue);
          font-size: 2rem;
        }

        span {
          margin-left: 1rem;
        }
      }

      a {
        margin-top: 2rem;
        color: var(--colorOrange);
      }
    }
  }
}
