@import "../../../styles/variables";
%users-board {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  background-color: var(--colorLightBlue);
  padding: 30px;
  border-radius: 12px;
  justify-items: center;

  @media screen and (min-width: 768px) and (max-width: 992px) {
    padding: 15px;
  }

  .single-team-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-radius: 12px;
    background-color: white;
    // max-width: 230px;
    height: 300px;
    width: 100%;
    padding: 20px;
    padding-bottom: 25px;

    @media screen and (min-width: 768px) and (max-width: 992px) {
      height: 255px;
    }

    header {
      display: flex;
      flex-direction: column;
      align-items: center;
      .user-image-container {
        border: 12px solid var(--colorOrange);
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 145px;
        height: 145px;

        @media screen and (min-width: 768px) and (max-width: 992px) {
          width: 100px;
          height: 100px;
          border-width: 8px;
        }

        .internal-border {
          border: 4px solid transparent;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          img {
            width: 140px;
            height: 140px;
            object-fit: cover;
          }

          @media screen and (min-width: 768px) and (max-width: 992px) {
            img {
              width: 100px;
              height: 100px;
            }
          }
        }
      }

      .user-info {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 0.5rem;
          margin-top: 0.5rem;
          white-space: nowrap;
        }
        p {
          font-size: 0.8rem;
        }
      }
    }

    footer {
      p {
        font-size: 0.8rem;
      }
    }

    &.lawyer {
      header .user-image-container {
        border-color: var(--colorOrange);
      }
    }
    &.agent {
      header .user-image-container {
        border-color: var(--colorLightBlue);
      }
    }
  }
}
.our-team-page__cards {
  background-image: url("/assets/scene/triangoliBR.svg");
  background-size: 90%;
  background-repeat: repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
  }

  .operators-boar,
  .map {
    flex: 1;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  .map-and-operators-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    align-items: flex-start;
    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }

    .map {
      width: 100%;
      img {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .operators-board {
      @extend %users-board;
      grid-template-columns: repeat(2, 1fr);
      word-break: break-all;
      text-align: center;
      @media screen and (max-width: 576px) {
        width: 100vw;
        margin: 0 -16px;
        border-radius: 0;
        grid-template-columns: repeat(2, 48%);
      }
      @media screen and (min-width: 768px) {
        width: 60%;
      }
      @media screen and (min-width: 993px) {
        width: 50%;
      }
    }
  }
  .agents-lawyers-board {
    margin-top: 80px;
    @extend %users-board;
    width: 100%;
    background-color: unset;
    grid-template-columns: repeat(1, 1fr);
    word-break: break-all;
    text-align: center;
    @media screen and (max-width: 576px) {
      width: 100vw;
      margin: 0 -16px;
      border-radius: 0;
      grid-template-columns: repeat(2, 48%);
    }
    @media screen and (min-width: 576px) and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 768px) and (max-width: 992px) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
    @media screen and (min-width: 993px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
