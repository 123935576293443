.banner-benefits-component {
  color: #fff;
  text-align: center;
  background: var(--colorLightBlue);
  background-image: url("/assets/scene/triangoliOrange.svg");
  background-repeat: no-repeat;
  background-position: center;

  @media screen and (max-width: 576px) {
    background-position: initial;
    background-repeat: repeat;
  }

  h2 {
    max-width: 700px;
    margin: 0 auto;
    @media screen and (max-width: 576px) {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }

  .benefits-list {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1rem;

    @media screen and (max-width: 992px) {
      grid-template-columns: minmax(0, 1fr);
    }

    .benefit {
      svg {
        height: 85px;
      }

      h6 {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      p {
        color: #fff;
        font-size: 1rem;
      }
      @media screen and (max-width: 576px) {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }
  }
}
