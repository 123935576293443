.map-density-component {
  position: relative;
  display: flex;
  justify-content: center;

  svg {
    height: 100%;
    width: 100%;

    @media screen and (max-width: 992px) {
      height: 400px;
      width: 400px;
    }

    @media screen and (max-width: 768px) {
      height: 300px;
      width: 300px;
    }

    &.active .st2 {
      fill: #ea5a1f;
    }

    & .st2 {
      fill: #46bbc0;
      stroke: #efedec;
      stroke-miterlimit: 10;
      transition: 1s;
    }
  }

  .change-view {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 1rem;
    text-transform: uppercase;
    border-radius: 1rem;
    width: max-content;

    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }

  .background {
    cursor: pointer;
    padding: 1rem 2rem;
    background: #fff;
    border-radius: 1rem;

    @media screen and (max-width: 768px) {
      padding: 0.5rem 1.5rem;
      width: 100%;
    }

    &.active {
      background: var(--colorOrange);
      color: #fff;
      transition: 1s;
      position: relative;
    }
  }

  .text {
    @media screen and (max-width: 768px) {
      width: max-content;
    }
  }
}
