.input-check-component {
  background: var(--colorBackground);
  border-radius: 999px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  &:hover {
    box-shadow: 0 0 2px var(--colorLightBlack);
  }

  svg {
    color: #fff;
    background: #fff;
    border-radius: 50%;
    padding: 0.2rem;
    font-size: 1.2rem;

    &.active {
      background: var(--colorOrange);
    }
  }

  &,
  toggle-free {
    svg {
      margin-left: 1rem;
    }
  }
}
