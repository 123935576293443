.container {
  max-width: 1140px;
  margin: 0 auto;

  @media screen and (max-width: 1200px) {
    max-width: 960px;
  }
  @media screen and (max-width: 992px) {
    max-width: 720px;
  }
  @media screen and (max-width: 768px) {
    max-width: 540px;
  }
}

.container-fluid {
  max-width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1200px) {
    max-width: 1100px;
  }
  @media screen and (max-width: 992px) {
    max-width: 980px;
  }
  @media screen and (max-width: 768px) {
    max-width: 740px;
  }
  @media screen and (max-width: 576px) {
    max-width: 560px;
  }
}

.pad-responsive {
  @media screen and (max-width: 1240px) {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}

.section {
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media screen and (max-width: 768px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &.space {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &.standard {
    padding-top: 80px;
    padding-bottom: 80px;

    @media screen and (max-width: 992px) {
      padding-top: 56px;
      padding-bottom: 56px;
    }
  }

  &.sub-navbar {
    padding-top: calc(80px - 4rem);
    padding-bottom: 80px;

    @media screen and (max-width: 992px) {
      padding-top: 20px;
      padding-bottom: 40px;
    }
  }

  &.only-bottom {
    padding-bottom: 80px;

    @media screen and (max-width: 992px) {
      padding-bottom: 24px;
    }
  }

  &.only-top {
    // padding-top: 2rem;
    padding-top: 80px;

    @media screen and (max-width: 992px) {
      padding-top: 24px;
    }
  }

  &.short {
    padding-top: 80px;
    @media screen and (max-width: 992px) {
      padding-top: 24px;
    }
  }
}

.bg-pink {
  background-color: var(--colorPink);
}

.bg-grey {
  background-color: var(--colorBackground);
}
