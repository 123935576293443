.dropdown-hamburger-menu {
  display: none;
  border-bottom: 1px solid var(--colorLightestBlack);
  margin-top: 1rem;

  &.active {
    display: flex;
    flex-direction: column;
  }

  a,
  .logout {
    font-size: 1.2rem;
    padding: 0.5rem;
    &:last-of-type {
      padding-bottom: 1rem;
    }
  }
}
