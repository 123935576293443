.error-page {
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text {
    font-size: 3rem;
    line-height: 1.5;

    @media screen and (max-width: 768px) {
      font-size: 2rem;
    }
  }

  a {
    color: var(--colorLightBlue);
    margin-top: 3rem;
    font-size: 1.5rem;

    &:hover {
      text-decoration: underline;
    }
  }
}
