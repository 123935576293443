.footer {
  color: var(--colorLightWhite);
  background: var(--colorOrange);

  @media screen and (max-width: 576px) {
    height: auto;
  }

  a {
    color: var(--colorLightWhite);

    &:hover {
      color: var(--colorLightBlue);
    }
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    padding: 4rem 2rem;

    @media screen and (max-width: 576px) {
      height: auto;
    }
  }

  .footer__top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding-bottom: 2rem;
    }

    .footer__logo {
      flex: 1;

      @media screen and (max-width: 768px) {
        padding-bottom: 1rem;
      }

      svg {
        width: 9rem;
        fill: #fff;
      }
    }

    .footer__menu {
      flex: 3;
      display: flex;
      justify-content: flex-start;

      @media screen and (max-width: 576px) {
        flex-direction: column;
      }

      & > * + * {
        margin-left: 6rem;

        @media screen and (max-width: 576px) {
          margin-left: 0;
        }
      }

      .menu {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 576px) {
          margin-bottom: 2rem;
        }

        h6 {
          color: #fff;
          margin-bottom: 1rem;
          text-transform: uppercase;
          font-size: 1rem;
          font-weight: 500;
        }

        a {
          margin-top: 1rem;
          font-weight: 500;
        }
      }
    }

    .social-list {
      display: flex;
      flex: 1;
      align-items: baseline;
      justify-content: flex-end;

      @media screen and (max-width: 768px) {
        padding-bottom: 2rem;
        justify-content: unset;
      }

      & > * + * {
        margin-left: 1rem;
      }

      svg {
        font-size: 1.5rem;
      }
    }
  }

  .footer__bottom {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    justify-content: space-between;
    font-size: 0.7rem;

    @media screen and (max-width: 576px) {
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    .footer-bottom__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .legal {
        display: flex;

        & > * + * {
          margin-left: 1rem;
        }
      }

      svg {
        margin-left: 1rem;
        width: 5rem;
        fill: #fff;

        &:hover {
          fill: var(--colorLightBlue);
        }
      }
    }
  }
}
