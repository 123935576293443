.card-real-estate__carousel {
  position: relative;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  .apartment-img {
    display: block;
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .apartment-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 70%;
      height: 150px;
    }
  }

  .prev-mini-img,
  .next-mini-img {
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem;

    &.swiper-button-disabled {
      filter: opacity(20%);
      pointer-events: none;
    }

    polyline {
      color: #fff;
      box-shadow: 0 0 5px #000;
    }
  }

  .prev-mini-img {
    left: 5%;
  }

  .next-mini-img {
    right: 5%;
  }
}
