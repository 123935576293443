.services-page__hero {
  text-align: center;

  @media screen and (max-width: 992px) {
    height: 45vh;
  }

  .illustration {
    display: flex;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      height: 300px;
    }

    .illustration-left {
      width: 45%;
      background-size: 145%;
      background-image: url("/assets/scene/7.svg");

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .illustration-right {
      width: 55%;
      background-size: 115%;
      background-image: url("/assets/scene/6.svg");

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .illustration-left,
    .illustration-right {
      height: 400px;
      background-position: center;
      background-repeat: no-repeat;

      @media screen and (max-width: 992px) {
        height: 380px;
        width: 100%;
        background-position: top center;
      }
    }
  }
}
