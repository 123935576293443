.why-asta-destinations__services {
  padding-bottom: 0;

  h2 {
    text-align: center;
    font-size: 3rem;
  }

  .service {
    display: flex;
    align-items: center;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }

    h3 {
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 2rem;
    }

    img,
    .description {
      width: 45%;

      @media screen and (max-width: 992px) {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }

    .number {
      width: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--colorLightBlue);
      font-size: 5rem;

      @media screen and (max-width: 992px) {
        width: 100%;
        -ms-flex-order: 1;
        order: -1;
        padding-bottom: 16px;
      }
    }

    .description {
      font-size: 1.2rem;
    }
  }
}
