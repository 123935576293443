.resultsMap {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding-bottom: 1.5rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  & > * {
    width: 50%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .apartments-list {
    padding-right: 5rem;
    padding-left: 4rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: start;
    column-gap: 2rem;
    row-gap: 2rem;

    @media screen and (max-width: 992px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 3rem;
      padding-left: 2rem;
      padding-right: 1rem;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .subscribe-for-more {
      padding: 3rem 0;
      grid-column: span 2 / span 2;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
    }
  }
}
