.visits-list {
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 1rem;
  }

  .visit {
    background: #fff;
    padding: 2rem;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
      padding: 1.2rem;
      flex-direction: column;

      & > * {
        width: 100%;
      }
    }

    .left {
      @media screen and (max-width: 768px) {
        margin-bottom: 1rem;
      }

      h3:hover {
        color: var(--colorOrange);
      }
    }

    .right {
      text-align: right;
      font-weight: 600;
      @media screen and (max-width: 768px) {
        font-size: 0.9rem;
      }
    }
  }
}
