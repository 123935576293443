.why-asta__contact-us {
  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }

  .form-component {
    width: 35%;
    max-width: 600px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
