.banner-premium-component {
  color: #fff;
  background: var(--colorLightBlue);
  text-align: center;
  padding-top: 5rem;
  height: 40rem;
  background-image: url("/assets/scene/11.svg");
  background-position: -30px 110px;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: 576px) {
    background-position: center 165%;
    background-size: 190%;
  }

  h2 {
    font-size: 4rem;
    margin-bottom: 1rem;
  }

  .price {
    margin-bottom: 3rem;
    font-size: 1.5rem;
    font-weight: 300;

    span {
      font-weight: 500;
      font-size: 3rem;
    }
  }
}
