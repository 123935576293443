.card-real-estate-extended-component {
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 1rem;
  background: #fff;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  display: flex;

  .description {
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 2rem;

    & > * + * {
      margin-top: 1.2rem;
    }

    h4 {
      font-size: 1.5rem;
      font-weight: 500;
      text-transform: capitalize;
    }
  }

  .heading-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > * + * {
      margin-left: 1rem;
    }

    p {
      text-transform: uppercase;
      line-height: 1rem;
    }

    .numbers {
      flex-shrink: 0;
      display: flex;
      align-items: center;

      & > * + * {
        margin-left: 1rem;
      }

      .price {
        font-size: 1.4rem;
        color: var(--colorOrange);
      }

      .m2 {
        font-size: 1.2rem;
        font-weight: 500;
      }

      .line {
        width: 1px;
        height: 1.3rem;
        background: #ccc;
      }
    }
  }

  .text {
    height: 2.4rem;
    line-height: 1.2rem;
    font-size: 0.8rem;
    overflow: hidden;
  }

  p,
  span {
    font-size: 1rem;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bottom__left {
      display: flex;

      p {
        margin-left: 5rem;
      }
    }

    .date {
      display: flex;
      align-items: center;

      svg {
        color: var(--colorLightBlue);
        font-size: 1.2rem;
      }

      span {
        margin-left: 0.5rem;
      }
    }
  }
}
