.hamburger-menu {
  position: fixed;
  z-index: 999999;
  top: 8rem;
  left: 0;
  width: 100vw;
  transform: translate3d(100%, 0px, 0px);
  transition: 0.3s;
  padding: 0 2rem;
  font-size: 1.5rem;
  background: var(--colorBackground);
  height: 100vh;
  display: flex;
  flex-direction: column;

  &.active {
    transform: none;
  }

  & > * + * {
    margin-top: 2rem;
  }

  .item-menu {
    .inizia {
      // width: 100%;
      a {
        color: #fff;
        display: flex;
        justify-content: center;

        // text-decoration: underline;
      }
    }
    .voice {
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        &.active {
          color: var(--colorLightBlue);
        }

        &.cart-link {
          position: relative;

          svg {
            font-size: 1.5rem;
          }
        }
      }

      .caret {
        cursor: pointer;

        &.active {
          transform: rotate(180deg);
        }
      }
    }
  }
}
