.calendar-page {
  .calendario-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;

      h1 {
        width: 100%;
        @media screen and (max-width: 768px) {
          margin-bottom: 1rem;
        }
      }
    }

    .select-container {
      background-color: #fff;
      border-radius: 10px;
      padding: 1rem 2rem;
      display: flex;
      align-items: center;
      // min-width: 40%;

      @media screen and (max-width: 576px) {
        // align-items: unset;
        flex-direction: column;
        width: 100%;
      }

      .input-select-component {
        width: 70%;
        min-width: 240px;
        @media screen and (max-width: 768px) {
          margin-bottom: 1rem;
        }
        @media screen and (max-width: 576px) {
          width: 100%;
        }
        // text-overflow: ellipsis; /* IE, Safari (WebKit) */
        //overflow: hidden; /* don't show excess chars */
        // white-space: nowrap; /* force single line */
        // width: 17px;
      }

      .btn {
        margin-left: 1rem;

        @media screen and (max-width: 768px) {
          margin-left: 0;
        }
        @media screen and (max-width: 576px) {
          width: 100%;
        }
      }
    }
  }

  .month-section {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .month {
      color: var(--colorOrange);
      font-weight: 500;
      font-size: 1.8rem;
      text-transform: capitalize;
    }

    .months-change {
      display: flex;
      align-items: center;
      position: relative;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .current-month {
        margin-right: 2rem;

        &:hover {
          color: var(--colorLightBlue);
        }

        @media screen and (max-width: 768px) {
          margin-right: 0;
          position: absolute;
          top: -1rem;
          cursor: pointer;
          width: max-content;
        }
      }
    }
  }

  .heading-calendario-aste {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    .day {
      text-align: center;
      background: var(--colorLightBlue);
      padding: 1rem 0;
      color: #fff;
      text-transform: capitalize;
      font-size: 1.2rem;
    }
  }

  .blocks {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    .block {
      height: 120px;
      // text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid #ddd;
      font-size: 1.2rem;
      padding: 0.5rem;

      @media screen and (max-width: 768px) {
        height: 70px;
        font-size: 0.9rem;
        padding: 0.2rem;
      }

      &.disabled {
        color: #ddd;
      }

      .day {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
      }

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 3rem;
        align-self: center;

        @media screen and (max-width: 768px) {
          align-self: flex-start;
          width: 2rem;
          height: 2rem;
          flex-shrink: 0;
        }
      }
    }
  }
}
