.get-stated-page {
  background-image: url("/assets/scene/triangoli.svg");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    background-repeat: repeat;
    background-position-y: 82%;
    background-size: 120%;
  }

  .get-stated-page__wrapper {
    text-align: center;

    .already-logged {
      margin-bottom: 1.5rem;
      font-size: 1.2rem;

      a {
        color: var(--colorLightBlue);
      }
    }

    .selection {
      margin-left: 1rem;
      font-size: 1.5rem;

      @media screen and (max-width: 768px) {
        margin-left: 0;
      }

      span {
        display: inline;
      }
    }
  }
}
