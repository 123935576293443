.researches-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;

  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .research {
    background: #fff;
    border-radius: 1rem;
    padding: 2rem;
    min-height: 8rem;
    position: relative;
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: 0.5rem;
    }

    .controls {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 0.5rem;
      right: 2rem;

      & > * + * {
        margin-top: 0.5rem;
      }

      svg {
        cursor: pointer;
        font-size: 2rem;

        &.search {
          color: var(--colorLightBlue);
        }

        &.delete {
          color: red;
        }
      }
    }
  }
}
