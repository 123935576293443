.account-page {
  .account-heading {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid var(--colorLightestBlack);
    position: relative;

    // & > * + * {
    //   margin-left: 4rem;
    // }

    h1 {
      flex-shrink: 0;
    }

    .navigation {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      font-weight: 500;
      padding: 2rem 0 1rem;
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: none;
      // padding-right: 4rem;

      &::-webkit-scrollbar {
        width: 0;
      }

      @media screen and (max-width: 992px) {
        // width: unset;
        justify-content: flex-start;
        padding: 2rem 2rem 1rem 0;

        &::after {
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 4rem;
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            var(--colorBackground) 100%
          );
          content: "";
        }
        & > * + * {
          padding-left: 2rem;
          flex-shrink: 0;
        }
      }
    }
  }

  .active {
    color: var(--colorOrange);
  }
}
