.why-asta__introduction {
  display: flex;
  align-items: center;

  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
  }

  .description,
  .img {
    width: 50%;
    align-self: stretch;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: 992px) {
      width: 100%;
      background-size: 100%;
      padding-top: 1.5rem;
    }
  }

  .img {
    background-image: url("/assets/scene/1.svg");

    @media screen and (max-width: 992px) {
      height: 300px;
    }
  }
}
