.home-page__app-section {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 576px) {
    flex-direction: column;
  }

  .app-description {
    display: flex;
    flex-direction: column;
    width: calc((100% / 12) * 5);

    @media screen and (max-width: 576px) {
      width: 100%;
    }

    h2 {
      margin-bottom: 3rem;
      font-size: 3rem;
    }

    p {
      margin-bottom: 2rem;
      line-height: 1.3;
      font-size: 1.2rem;
    }

    .app-description__download {
      // display: grid;
      // grid-template-columns: repeat(2, minmax(0, 1fr));
      // column-gap: 2rem;
      // row-gap: 1rem;
      display: flex;
      gap: 16px;

      @media screen and (max-width: 768px) {
        // padding: 0 2rem;
        grid-template-columns: minmax(0, 1fr);
      }
      .apple-store {
        width: 120px;
        height: 40px;
        margin-right: 1rem;
        background-image: url("/assets/app-store.svg");
      }
      .play-store {
        width: 135px;
        height: 40px;

        background-image: url("/assets/google-play.svg");
        background-size: cover;
      }

      // a {
      //   background: var(--colorLightBlue);
      //   padding: 0.6rem;
      //   display: flex;
      //   align-items: center;
      //   border-radius: 0.6rem;
      //   color: #fff;
      //   font-weight: 500;

      //   &:hover {
      //     background: #fff;
      //     color: var(--colorLightBlue);

      //     svg {
      //       color: var(--colorLightBlue);
      //     }
      //   }

      //   & > * + * {
      //     margin-left: 1rem;
      //   }

      //   svg {
      //     color: #fff;
      //     font-size: 2rem;
      //   }

      //   p {
      //     margin-bottom: 0;
      //   }
      // }
    }
  }

  .app-illustration {
    width: 50%;

    @media screen and (max-width: 576px) {
      order: -1;
      width: 100%;
    }

    img {
      max-width: 80%;
      margin: 0 auto;
      display: block;

      @media screen and (max-width: 768px) {
        margin-bottom: 2rem;
      }
    }
  }
}
