.info-real-estate-page__map-box {
  position: sticky !important;
  top: 9rem;
  height: calc(100vh - 9rem);

  @media screen and (max-width: 768px) {
    width: 100%;
    height: unset;
    margin-bottom: 2rem;
    height: 500px;
  }

  .toggle-free {
    box-shadow: 0 0 5px var(--colorLightestBlack);
    position: absolute;
    top: 1rem;
    right: 5rem;

    @media screen and (max-width: 768px) {
      bottom: 1rem;
      left: 1rem;
      top: unset;
      right: unset;
    }
  }

  .info-window {
    display: flex;
    flex-direction: column;

    .subscribe-for-more {
      a {
        color: var(--colorOrange);

        // &:hover {
        //   text-decoration: underline;
        // }
      }
    }

    // &.anchor:hover {
    //   text-decoration: underline;
    // }

    & > * + * {
      padding-top: 4px;
    }
  }
}

.info-real-estate-page__map-box__mobile {
  height: calc(100vh - 9rem);
  @media screen and (max-width: 768px) {
    width: 100%;
    height: unset;
    margin-bottom: 2rem;
    height: 500px;
  }

  .toggle-free {
    box-shadow: 0 0 5px var(--colorLightestBlack);
    position: absolute;
    top: 1rem;
    right: 5rem;

    @media screen and (max-width: 768px) {
      bottom: 1rem;
      left: 1rem;
      top: unset;
      right: unset;
    }
  }

  .info-window {
    display: flex;
    flex-direction: column;

    .subscribe-for-more {
      a {
        color: var(--colorOrange);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.anchor:hover {
      text-decoration: underline;
    }

    & > * + * {
      padding-top: 4px;
    }
  }
}
