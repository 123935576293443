.contact-us-page {
  background-image: url("/assets/scene/triangoliContatti.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);

  @media screen and (max-width: 992px) {
    flex-direction: column;
    grid-template-columns: repeat(1, auto);
  }

  h1 {
    font-size: 5rem;
    @media screen and (max-width: 992px) {
      font-size: 3.5rem;
    }
  }
  .left-side {
    width: 75%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 992px) {
      margin-bottom: 16px;
      width: 90%;
    }
  }

  .contact-section {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  p {
    line-height: 1.2rem;
  }
}
