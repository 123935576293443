.for-customers-page__how {
  .for-customers-page__how__wrapper {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;

      & > * {
        width: 100% !important;
      }
    }

    .description {
      width: 30%;
    }

    table {
      width: 60%;
      border-collapse: collapse;

      @media screen and (max-width: 992px) {
        margin-top: 1.5rem;
      }

      td {
        border: 1px solid var(--colorLightestBlack);
        padding: 1rem;

        &:first-child {
          font-size: 1rem;
          min-width: 150px;
          color: #fff;
          background-color: var(--colorOrange);
        }
      }
    }
  }
}
