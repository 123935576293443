.navbar__dropdown {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  cursor: pointer;

  &:hover {
    color: var(--colorLightBlue);
  }

  div.active {
    padding: 12px 0;
  }

  .link-dropdown {
    display: flex;
    align-items: center;

    span {
      margin-left: 0.5rem;
    }
  }

  .menu {
    position: absolute;
    box-shadow: 0 0 1px var(--colorLightestBlack);
    min-width: 100%;
    top: 40px;

    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border-radius: 1rem;
    padding: 0.5rem 1rem;

    a,
    .logout {
      cursor: pointer;
      display: block;
      min-width: 100%;
      width: max-content;
      padding: 0.5rem 0;

      &:hover {
        color: var(--colorLightBlue);
      }
    }
  }
}
