.cart-page {
  .cart {
    .cart__heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .details {
      max-width: 90%;
      margin: 0 auto;

      .details__heading {
        display: flex;
        justify-content: flex-end;

        svg {
          cursor: pointer;
          font-size: 2rem;

          &:hover {
            color: red;
          }
        }
      }

      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 2rem;
        position: relative;

        th {
          border-bottom: 1px solid var(--colorBlack);
          padding: 1.5rem 0;
          text-transform: uppercase;
          font-size: 0.8rem;
          text-align: center;
          font-weight: 500;

          &:first-child {
            text-align: left;
          }
          @media screen and (max-width: 576px) {
            font-size: 0.6rem;
            padding: 1.5rem 8px;
          }
        }

        td {
          border-bottom: 1px solid var(--colorBlack);
          padding: 5rem 0;
          text-align: center;
          font-size: 1.5rem;
          vertical-align: middle;

          @media screen and (max-width: 576px) {
            font-size: 1rem;
            padding: 3rem 0;
          }

          &:first-child {
            text-transform: uppercase;
            text-align: left;
            font-weight: 500;
          }

          &:nth-child(2) {
            color: var(--colorOrange);
            font-weight: 500;
          }
        }
      }

      .table-bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media screen and (max-width: 576px) {
          flex-direction: column;
          align-items: flex-end;
        }

        .coupon {
          background-color: #fff;
          border-radius: 50px;
          padding: 0.5rem;
          display: flex;

          input {
            padding: 0.5rem 1rem;
            margin-right: 8px;
          }

          .btn {
            padding: 0 1rem;
            font-size: 0.8rem;
          }
        }

        .coupon-checked {
          margin-top: 1rem;
          text-align: center;
          color: red;

          &.valid {
            color: green;
          }
        }

        .right {
          text-align: right;

          @media screen and (max-width: 576px) {
            align-self: flex-end;
            padding-top: 1rem;
            padding-bottom: 1rem;
          }
        }
      }
    }

    .empty-cart {
      font-size: 2rem;
      display: flex;
      justify-content: center;
      padding-top: 3rem;
      color: var(--colorLightestBlack);
      @media screen and (max-width: 576px) {
        min-height: 50vh;
        align-items: center;
      }
    }

    button {
      float: right;
      @media screen and (max-width: 576px) {
        margin-bottom: 32px;
      }
    }
  }

  .checkout {
    .total-container {
      padding-top: 2rem;
      width: calc((100% / 12) * 10);
      text-align: right;
    }

    .form-container {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      row-gap: 2rem;

      @media screen and (max-width: 576px) {
        grid-template-columns: minmax(0, 1fr);
      }

      .right-box {
        svg {
          width: 7rem;
        }
      }
    }

    .recap {
      .recap-row {
        border-top: 1px solid var(--colorBlack);
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        font-weight: 500;

        &:first-of-type {
          border-top: 1px solid transparent;
        }

        &:nth-child(2) {
          font-size: 0.8rem;
        }

        .plan-type {
          text-transform: uppercase;
        }

        .prices-plans {
          color: var(--colorOrange);
          display: flex;

          & > * + * {
            margin-left: 1.5rem;
          }
        }
      }

      .checkbox-container {
        padding-top: 1rem;
        display: flex;
        justify-content: center;
      }

      .buttons-container {
        padding-top: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .back-to-cart {
          display: flex;
          align-items: center;

          span {
            margin-left: 1rem;
          }
        }
      }
    }
  }

  .total {
    padding-bottom: 4rem;
    font-size: 1.3rem;
    @media screen and (max-width: 576px) {
      padding-bottom: 1.5rem;
    }
    span {
      font-weight: 500;
      padding-left: 1rem;
      font-size: 2rem;
    }
  }
}
