.forms-page {
  .intro {
    display: flex;

    h1 {
      text-align: center;
      padding: 0 3rem;
    }
  }

  .bg-section {
    background-image: url("/assets/scene/triangoliFAQ.svg");
    background-position: center;
    background-repeat: repeat;
  }

  .select-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    align-items: center;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      justify-content: center;
      // align-items: unset;
    }

    .input-select-component {
      width: 100%;
    }

    .btn {
      margin-left: 1rem;

      @media screen and (max-width: 768px) {
        margin-left: 0;
      }
    }
  }

  .moduli-list {
    // width: 600px;
    display: grid;
    grid-template-columns: 600px;
    justify-content: center;
    column-gap: 2rem;

    @media screen and (max-width: 576px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .modulo {
      width: 100%;
      height: 5rem;
      border-bottom: 2px solid #000;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 1.2rem;
      }

      svg {
        color: var(--colorOrange);
        margin-left: 2rem;
        font-size: 2rem;
        flex-shrink: 0;
      }
    }
  }
}
